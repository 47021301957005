
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
html, body{
  margin: 0;
  padding: 0;font-family: 'Roboto', sans-serif;
}


.container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
}

.header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 460px;
  background-image: url('./bg.jpg');
  background-position: center center;
}

.header-bg{
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  min-height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding-top: 48px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  max-width: 620px;
  width: 100%;
}

.header-content span{
  font-family: 'Roboto',Arial,sans-serif;
  font-weight: 600;
  text-align: center;
  color: #000000;
  letter-spacing: 2.5px;
}

.header-content h1{
  font-size: 64px;
  line-height: 1.23;
  font-family: 'Roboto',Arial,sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.content{
  width: 100%;
  max-width: 620px;
  padding: 24px 8px;
  box-sizing: border-box;
  padding-bottom: 128px;
}

.input{
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  min-height: 40px;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  margin-bottom: 9px;
  font-size: 18px;
  margin-top: 10px;
}

.main-btn{
  background-color: #3f3f3f;
  color: white;
  font-family: 'Roboto',Arial,sans-serif;
  font-weight: 600;
  /* color: #000000; */
  font-size: 21px;
  margin-top: 16px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
  transition: 0.1s ease-in-out;
  cursor: pointer;
}
.main-btn:hover{
  background-color: black;
}

.content .text{
  margin-top: 12px;
  font-size: 18px;
}

.label{
  /* padding-top: 12px; */
  font-size: 18px;
  /* margin-bottom: 523px; */
}

.labelB{
  /* padding-top: 12px; */
  font-size: 22px;
  font-weight: 600;
  /* margin-bottom: 523px; */
}